import { Link } from 'react-router-dom';
import heroBannerImg from '../../images/hero-banner.jpg';

function HeroSection() {
  return (
    <section className="section hero" id="home" aria-label="hero">
      <div className="container">
        <div className="hero-content">
          <p className="hero-subtitle">Custom Software Development</p>

          <h1 className="h1 hero-title">Strategy Meets Technology</h1>

          <p className="hero-text">We offer software solutions to businesses seeking a partner for their custom software development and technology strategy needs.</p>

          <Link to="contact" className="btn btn-primary">
            Schedule a Free Expert Session
          </Link>
        </div>

        <figure className="hero-banner">
          <img src={heroBannerImg} width="720" height="673" alt="hero banner" className="w-100" />
        </figure>
      </div>
    </section>
  );
}

export default HeroSection;
