import { Link } from 'react-router-dom';
import ctaBackground from '../../images/cta-bg.jpg';

function CallToAction() {
  return (
    <section className="section cta" aria-label="cta" style={{ backgroundImage: `url(${ctaBackground})` }}>
      <div className="container">
        <p className="cta-subtitle">So What Is Next?</p>

        <h2 className="h2 section-title">Bring Your Digital Vision to Life</h2>

        <Link to="/contact" className="btn btn-secondary">
          Schedule a Free Expert Session
        </Link>
      </div>
    </section>
  );
}

export default CallToAction;
