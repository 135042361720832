import { IonIcon } from '@ionic/react';
import { menuOutline, closeOutline } from 'ionicons/icons';

import { useState, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import logoImg from '../images/logo/logo.png';

function MainNavigation() {
  const [navActive, setNavActive] = useState(false);
  const [headerActive, setHeaderActive] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 100) {
      setHeaderActive(true);
    } else {
      setHeaderActive(false);
    }
  }, []);

  useEffect(() => {
    const debounceScroll = debounce(handleScroll, 100);
    window.addEventListener('scroll', debounceScroll);
    return () => {
      window.removeEventListener('scroll', debounceScroll);
    };
  }, [handleScroll]);

  const toggleNavbar = () => {
    setNavActive(!navActive);
  };

  const closeNavbar = () => {
    setNavActive(false);
  };

  return (
    <header className={`header${headerActive ? ' active' : ''}`} data-header>
      <div className="container">
        <NavLink to="/">
          <img src={logoImg} className="logo" alt="Strategy Sparks' Logo" />
        </NavLink>
        <nav className={`navbar container${navActive ? ' active' : ''}`} data-navbar>
          <ul className="navbar-list">
            <li>
              <NavLink to="/" onClick={closeNavbar} className={({ isActive }) => (isActive ? 'navbar-link active' : 'navbar-link')}>
                Home
              </NavLink>
            </li>

            <li>
              <NavLink to="/projects" onClick={closeNavbar} className={({ isActive }) => (isActive ? 'navbar-link active' : 'navbar-link')}>
                Projects
              </NavLink>
            </li>

            <li>
              <NavLink to="/services" onClick={closeNavbar} className={({ isActive }) => (isActive ? 'navbar-link active' : 'navbar-link')}>
                Services
              </NavLink>
            </li>

            <li>
              <NavLink to="/contact" onClick={closeNavbar} className={({ isActive }) => (isActive ? 'navbar-link active' : 'navbar-link')}>
                Work with Us
              </NavLink>
            </li>
          </ul>
        </nav>

        <button className={`nav-toggle-btn`} aria-label="Toggle menu" onClick={toggleNavbar}>
          {navActive ? <IonIcon icon={closeOutline} /> : <IonIcon icon={menuOutline} />}
        </button>
      </div>
    </header>
  );
}

export default MainNavigation;

// Debounce function
function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
