import { IonIcon } from '@ionic/react';
import { desktop, phonePortrait, colorPalette, lockClosed, chatboxEllipses, construct } from 'ionicons/icons';

function ServiceCard({ icon, title, text, bgColor }) {
  return (
    <li>
      <div className="service-card">
        <div className="card-icon" style={{ backgroundColor: bgColor }}>
          <IonIcon icon={icon} />
        </div>
        <h3 className="h3">
          <div className="card-title">{title}</div>
        </h3>
        <p className="card-text">{text}</p>
      </div>
    </li>
  );
}

function ServicesSection() {
  const services = [
    { icon: desktop, title: 'Web Development', text: 'Creating your perfect website is our mission. Our top-tier custom web services ensure your vision comes to life precisely.', bgColor: '#6610f2' },
    { icon: phonePortrait, title: 'App Development', text: 'Turn your app idea into a reality. Our team of skilled app developers offers customized solutions to bring your vision to life.', bgColor: '#fc3549' },
    { icon: colorPalette, title: 'Graphic Design', text: 'Great design can transform your brand. Our expert team of graphic designers offers custom solutions to help you make a statement.', bgColor: '#ffb700' },
    { icon: chatboxEllipses, title: 'IT Consulting', text: 'Transform your IT challenges into opportunities with our consulting. Get expert tech insights and strategies to elevate your business.', bgColor: '#13c4a1' },
    { icon: lockClosed, title: 'Cyber Security', text: 'Protecting your data is our top priority. We offer industry-leading security and privacy solutions to ensure your information is safe.', bgColor: '#ff612f' },
    { icon: construct, title: 'Tech Support', text: 'Rely on our tech support for immediate assistance. We solve your technical issues fast, keeping your operations seamless and efficient.', bgColor: '#00d280' },
  ];

  return (
    <section className="section service" id="service" aria-label="service">
      <div className="container">
        <h2 className="h2 section-title">Services We Provide</h2>
        <p className="section-text">We're a full-service company, which means we've got you covered on design and security, right through to development. You'll form a lasting relationship with us.</p>
        <ul className="grid-list">
          {services.map((service, index) => (
            <ServiceCard key={index} icon={service.icon} title={service.title} text={service.text} bgColor={service.bgColor} />
          ))}
        </ul>
      </div>
    </section>
  );
}

export default ServicesSection;
