import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const showToast = (message, type) => {
  const commonOptions = {
    position: 'top-center',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: Bounce,
  };

  switch (type) {
    case 'success':
      toast.success(message, commonOptions);
      break;
    case 'error':
      toast.error(message, commonOptions);
      break;
    default:
      console.warn('Unsupported toast type');
  }
};

export { showToast };
