function Footer({ isGray }) {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={`footer ${isGray ? ' footer--gray' : ''}`}>
      <div className="container">
        <p className="copyright">
          &copy; <span className="year">{currentYear}</span> All Rights Reserved by <span className="copyright-link">Strategy Sparks</span>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
