import { Outlet } from 'react-router-dom'; // {useNavigation}
import MainNavigation from '../components/MainNavigation';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import ReactGA from 'react-ga4'; // Check see if GA tracks pages without this

function RootLayout() {
  // uppon path change, scroll to top of the page
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    // ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search }); // Check see if GA tracks pages without this
  }, [location]);

  //navigation is used to see the routes transition state, loading is added to the comopnent which is already visible
  // const navigation = useNavigation();

  return (
    <>
      <MainNavigation />

      {/* {navigation.state === 'loading' && <p>Loading...</p>} */}
      <main>
        <article>
          <Outlet />
        </article>
      </main>
    </>
  );
}

export default RootLayout;
