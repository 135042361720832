import React from 'react';

function VideoSection() {
  const vimeoURL = 'https://player.vimeo.com/video/911560771';

  return (
    <section className="section video" id="video" aria-label="video">
      <h2 className="h2 section-title">Our Portfolio Highlights</h2>

      <p className="section-text">Dive into our curated selection of projects where creativity meets technology, showcasing our expertise across a spectrum of innovative solutions.</p>

      <div className="video-wrapper">
        <div className="video-iframe">
          <iframe src={vimeoURL} frameBorder="0" allowFullScreen allow="autoplay; picture-in-picture" title="Embedded Vimeo Video"></iframe>
        </div>
      </div>
    </section>
  );
}

export default VideoSection;
