import React from 'react';
import { IonIcon } from '@ionic/react';
import { star } from 'ionicons/icons';

function TestimonalSection() {
  const vimeoURL = 'https://player.vimeo.com/video/916518885';

  return (
    <section className="section testimonials" id="testimonials" aria-label="testimonials">
      <h2 className="h2 section-title">What Our Pros Say</h2>

      <div className="container container--testimonial">
        <div className="testimonials-video-wrapper">
          <div className="testimonials-video-iframe">
            <iframe src={vimeoURL} frameBorder="0" allowFullScreen allow="autoplay; picture-in-picture" title="Embedded Vimeo Video"></iframe>
          </div>
        </div>

        <div>
          <div className="review">
            <IonIcon icon={star} />
            <IonIcon icon={star} />
            <IonIcon icon={star} />
            <IonIcon icon={star} />
            <IonIcon icon={star} />
          </div>
          <p className="section-text">“It was like flipping a switch. Our bookings jumped up by 30%, and we cut our admin costs in half.”</p>

          <p className="testimonial-author">&ndash; Robert Miller</p>
          <p className="testimonial-author">&ndash; Operational Manager, Simon's Shine Shop</p>
          <p className="testimonial-author">&ndash; Chicago, IL</p>
        </div>
      </div>
    </section>
  );
}

export default TestimonalSection;
