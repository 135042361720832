import Footer from '../components/Footer';
import ContactSection from '../components/sections/ContactSection';

function ContactPage() {
  return (
    <>
      <ContactSection />
      <Footer isGray={true} />
    </>
  );
}

export default ContactPage;
