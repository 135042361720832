import sarahImg from '../../images/team/sarah.jpg';
import adamImg from '../../images/team/adam.jpg';
// import samanImg from '../../images/team/saman.jpg';
import arielImg from '../../images/team/ariel.jpg';
import millaImg from '../../images/team/milla.jpg';

import React from 'react';

function TeamMember({ name, title, email, imgSrc }) {
  return (
    <div className="row__item">
      <div className="our-team">
        <div className="picture">
          <img className="img-fluid" src={imgSrc} alt="profile" />
        </div>
        <div className="team-content">
          <h3 className="name">{name}</h3>
          <h4 className="title">{title}</h4>
        </div>
        <ul className="social">
          <a href={`mailto:${email}`} className="fa fa-envelope" aria-hidden="true">
            {email}
          </a>
        </ul>
      </div>
    </div>
  );
}

function TeamSection() {
  const teamMembers = [
    {
      name: 'Sarah Gray',
      title: 'Creative Director',
      email: 'sarah@strategysparks.com',
      imgSrc: sarahImg,
    },
    {
      name: 'Ariel Alter',
      title: 'Technical Director',
      email: 'ariel@strategysparks.com',
      imgSrc: arielImg,
    },
    {
      name: 'Adam Baker',
      title: 'Lead Developer',
      email: 'adam@strategysparks.com',
      imgSrc: adamImg,
    },
    {
      name: 'Milla Sanz',
      title: 'Database Director',
      email: 'milla@strategysparks.com',
      imgSrc: millaImg,
    },
  ];

  return (
    <section className="section team" id="team" aria-label="team">
      <h2 className="h2 section-title">Our Team</h2>

      <p className="section-text">Behind every successful project is our team, approaching challenges with strategic insight, technical expertise, and a relentless drive for perfection.</p>

      <div className="container">
        <div className="row">
          {teamMembers.map((member, index) => (
            <TeamMember key={index} {...member} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default TeamSection;
