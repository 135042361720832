const NotFound = function ({ title, message }) {
  return (
    <>
      <main>
        <article>
          <section className="section project">
            <h2 className="h2 section-title">{title}</h2>
            <p className="section-text">{message}</p>
          </section>
        </article>
      </main>
    </>
  );
};

export default NotFound;
