import { IonIcon } from '@ionic/react';
import { globe, checkmarkCircle } from 'ionicons/icons';

import AboutBannerImg from '../../images/about-banner.jpg';

function AboutSection() {
  const SuccessItem = ({ text }) => (
    <li className="about-item">
      <IonIcon icon={checkmarkCircle} />
      <p className="section-text">{text}</p>
    </li>
  );

  return (
    <section className="section about" id="about" aria-label="about">
      <div className="container container--about">
        <div className="about-banner img-holder" style={{ '--width': '720', '--height': '960' }}>
          <img src={AboutBannerImg} width="720" height="960" loading="lazy" alt="about banner" className="img-cover" />

          <div className="play-btn" aria-label="Play video">
            <IonIcon icon={globe} />
          </div>
        </div>

        <div className="about-content">
          <h2 className="h2 section-title">About Us</h2>

          <p className="section-text">We create tailored software that automates processes, enhances customer engagement, and streamlines operations, helping businesses thrive in the digital era. Our mission is to empower businesses to thrive in the digital era, leveraging cutting-edge technology to drive innovation and growth.</p>

          <h3 className="h3">Consultation Solutions</h3>

          <p className="section-text">Beyond being a typical custom software development company, our experts are always ready to answer your questions and share their knowledge through consulting. We aim to deliver software of exceptional value to your business, exceeding your expectations.</p>

          <h3 className="h3">Our Successes</h3>

          <ul className="about-list">
            <SuccessItem text="Developed a car detailing booking platform, boosting bookings by 30% and cutting admin costs by 50%" />
            <SuccessItem text="Added virtual tours to a real estate site, increasing remote viewings by 25% and offers by 20%" />
            <SuccessItem text="Integrated payment and invoicing on a plumbing site, enhancing cash flow and cutting delays by 60%" />
            <SuccessItem text="Implemented Project Management Software for a construction firm, boosting project efficiency by 30%" />
            {/* <SuccessItem text="Created an inventory and scheduling system for pest control, streamlining operations and boosting efficiency by 45%" /> */}
          </ul>

          {/* <h3 className="h3">Our Culture</h3>

          <p className="section-text">Our culture is built on collaboration, creativity, and innovation. We are a team of passionate professionals who are dedicated to delivering the best possible solutions for our clients. We encourage a culture of open communication and continuous learning, and we are committed to providing a work environment that fosters growth and development.</p> */}
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
