import React, { useRef, useState } from 'react';
import { IonIcon } from '@ionic/react';
import { mailOutline, mapOutline } from 'ionicons/icons';
import emailjs from '@emailjs/browser';
import { ToastContainer } from 'react-toastify';
import { showToast } from '../../util/ToastUtility';
import ReactGA from 'react-ga4';

function ContactSection() {
  const form = useRef();
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  // Below are used for testing showToast
  // const buttonOne = () => {
  //   showToast(`Your message has been sent. We'll respond within 24 hours.`, 'success');
  // };
  // const buttonTwo = () => {
  //   showToast('Unable to deliver message. Contact us by email instead.', 'error');
  // };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSendingEmail(true);

    emailjs
      .sendForm('service_ia1qdok', 'template_o5izpnn', form.current, {
        publicKey: 'LsQ-pUR14TllSEfQu',
      })
      .then(
        () => {
          showToast(`Your message has been sent. We'll respond within 24 hours.`, 'success');
          form.current.reset(); // Resets the form
          setIsSendingEmail(false);

          // Log event to Google Analytics
          ReactGA.event({
            category: 'Lead',
            action: 'lead_generated',
            label: 'Contact Form',
          });
        },
        (error) => {
          showToast('Unable to deliver message. Contact us by email instead.', 'error');
          // console.log('FAILED...', error.text);
          setIsSendingEmail(false);
        }
      );
  };

  return (
    <section className="section contact" id="contact" aria-label="contact">
      <ToastContainer />
      {/* Below are used for testing showToast */}
      {/* <button onClick={buttonOne}>Button One</button>
      <button onClick={buttonTwo}>Button Two</button> */}

      <div className="container">
        <h2 className="h2 section-title">Reach Out To Us</h2>

        <p className="section-text">Let's connect and take your business to the next level. Fill out the contact form or email us, and we'll get back to you within 24 hours.</p>

        <form name="contact" className="contact-form" ref={form} onSubmit={sendEmail}>
          <input type="hidden" name="form-name" value="contact" />

          <div className="input-wrapper">
            <input type="text" name="name" aria-label="name" placeholder="Your name*" required className="input-field" />

            <input type="email" name="email" aria-label="email" placeholder="Email address*" required className="input-field" />
          </div>

          <div className="input-wrapper">
            <input type="text" name="subject" aria-label="subject" placeholder="Subject" className="input-field" />

            <input type="number" name="phone" aria-label="phone" placeholder="Phone number" className="input-field" />
          </div>

          <textarea name="message" aria-label="message" placeholder="Your message...*" required className="input-field"></textarea>

          <button type="submit" className="btn btn-primary" disabled={isSendingEmail}>
            {isSendingEmail ? 'Sending...' : 'Send Message'}
          </button>
        </form>

        <ul className="contact-list">
          <li className="contact-item">
            <div className="contact-card">
              <div className="card-icon">
                <IonIcon icon={mailOutline} />
              </div>

              <div className="card-content">
                <h3 className="h3 card-title">Mail Here</h3>

                <a href="mailto:contact@strategysparks.com" className="card-link">
                  contact@strategysparks.com
                </a>
              </div>
            </div>
          </li>

          <li className="contact-item">
            <div className="contact-card">
              <div className="card-icon">
                <IonIcon icon={mapOutline} />
              </div>

              <div className="card-content">
                <h3 className="h3 card-title">Our Headquarters</h3>

                <address className="card-address">
                  401 Bay St, Fl 16, Toronto, ON, Canada
                  {/* <br /> */}
                  {/* IL 20001, USA */}
                </address>
              </div>
            </div>
          </li>

          {/* <li className="contact-item">
            <div className="contact-card">
              <div className="card-icon">
                <IonIcon icon={headsetOutline} />
              </div>

              <div className="card-content">
                <h3 className="h3 card-title">Call Here</h3>

                <a href="tel:+1234567890" className="card-link">
                  +1 23 456 7890
                </a>
                <a href="tel:+2414524526" className="card-link">
                  +1 41 452 4526
                </a>
              </div>
            </div>
          </li> */}
        </ul>
      </div>
    </section>
  );
}

export default ContactSection;
