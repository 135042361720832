import React from 'react';

// Import images just once
import projectOneImg from '../../images/projects/project-1.jpg';
import projectTwoImg from '../../images/projects/project-2.jpg';
import projectThreeImg from '../../images/projects/project-3.jpg';
import projectFourImg from '../../images/projects/project-4.jpg';
import projectFiveImg from '../../images/projects/project-5.jpg';
import projectSixImg from '../../images/projects/project-6.jpg';
import projectSevenImg from '../../images/projects/project-7.jpg';
import projectEightImg from '../../images/projects/project-8.jpg';
import projectNineImg from '../../images/projects/project-9.jpg';

// Create an array to store project details
const projects = [
  { img: projectSevenImg, subtitle: 'Booking Platform', title: "Simon's Shine Shop", description: 'Car Detailing', link: 'https://www.simonsshineshop.com/' },
  { img: projectFourImg, subtitle: 'Real Estate Listing Software', title: 'Bozeman Real Estate', description: 'Real Estate', link: 'https://bozemanrealestate.group/real-estate/bozeman-real-estate?view=search&type=&keywords=&minPrice=&maxPrice=&order=postDate    +Desc&bedrooms=&bathrooms=&sqFeet=&acres=&year=&stories=' },
  { img: projectNineImg, subtitle: 'Payment and Invoicing Software', title: 'Bright Side Plumbing', description: 'Plumbing', link: 'https://www.callbrightside.com/financing/' },
  { img: projectOneImg, subtitle: 'Case Management Software', title: "Scott's Law Firm", description: 'Law Firm', link: 'https://www.lawfirm1.com/' },
  { img: projectSixImg, subtitle: 'Project Management Software', title: 'MCKeon Group', description: 'Construction', link: 'https://mckeon.ie/' },
  { img: projectThreeImg, subtitle: 'Appointment Scheduling Software', title: 'King Barber', description: 'Barber Shop', link: 'https://kingbarber.us/' },
  { img: projectTwoImg, subtitle: 'Table Management System', title: 'Big Kahuna', description: 'Restaurant', link: 'https://bigkahunaatl.com/' },
  { img: projectEightImg, subtitle: 'Self Serve Payment Platform', title: 'Tratta', description: 'Fintech', link: 'https://www.tratta.io/' },
  { img: projectFiveImg, subtitle: 'Ecommerce Platform', title: 'The T-Shirt Lady', description: 'Ecommerce', link: 'https://thetshirtlady.com/' },
];

function ProjectsSection() {
  return (
    <section className="section project" id="project" aria-label="project">
      <div className="container">
        <h2 className="h2 section-title">Our Recent Projects</h2>
        <p className="section-text">Take a look at some of our recent projects and see how we've helped our clients achieve their goals. From software and web development to graphic design.</p>
        <ul className="grid-list">
          {projects.map((project, index) => (
            <li key={index}>
              <div className="project-card">
                <figure className="card-banner img-holder" style={{ '--width': 510, '--height': 700 }}>
                  <img src={project.img} width="510" height="700" loading="lazy" alt={project.description} className="img-cover" />
                </figure>
                <div className="card-content">
                  <p className="card-subtitle">{project.subtitle}</p>
                  <h3 className="h3">
                    <a href={project.link} target="_blank" rel="noreferrer" className="card-title">
                      {project.title}
                      <br />
                      &mdash; {project.description}
                    </a>
                  </h3>
                  <a href={project.link} target="_blank" rel="noreferrer" className="btn btn-primary">
                    View Project
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default ProjectsSection;
