import { useRouteError } from 'react-router-dom';
import MainNavigation from '../components/MainNavigation';
import NotFound from '../components/NotFound';

function ErrorPage() {
  const error = useRouteError();

  let title = 'An error occured!';
  let message = 'Something went wrong!';

  if (error.status === 500) {
    // message = JSON.parse(error.data).message; //convert the string to an object, in order to access the message property
    message = error.data.message; //.json automatically does all the parsing for us
  }

  if (error.status === 404) {
    title = 'Page Not Found!';
    message = 'The page you requested could not be found.';
  }

  return (
    <>
      <MainNavigation />

      <NotFound title={title} message={message} />
    </>
  );
}

export default ErrorPage;
