import Footer from '../components/Footer';
import ServicesSection from '../components/sections/ServicesSection';

function ServicesPage() {
  return (
    <>
      <ServicesSection />
      <Footer isGray={true} />
    </>
  );
}

export default ServicesPage;
