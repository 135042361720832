import Footer from '../components/Footer';
import ProjectsSection from '../components/sections/ProjectsSection';

function ProjectsPage() {
  return (
    <>
      <ProjectsSection />
      <Footer isGray={false} />
    </>
  );
}

export default ProjectsPage;
