import AboutSection from '../components/sections/AboutSection';
import HeroSection from '../components/sections/HeroSection';
import CallToAction from '../components/sections/CallToAction';
import Footer from '../components/Footer';
import VideoSection from '../components/sections/VideoSection';
import TeamSection from '../components/sections/TeamSection';
import TestimonalSection from '../components/sections/TestimonialSection';

function HomePage() {
  return (
    <>
      <HeroSection />
      <VideoSection />
      <CallToAction />
      <TestimonalSection />
      <AboutSection />
      <TeamSection />
      <Footer isGray={false} />
    </>
  );
}

export default HomePage;
